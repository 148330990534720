import {React, useEffect} from "react";
import { makeStyles} from '@material-ui/core/styles';
import ImgMediaCard from "./ImgMediaCard";
import { ImgMediaCardLink } from "./ImgMediaCard";
import { Link, useHistory } from "react-router-dom";
import data from './dataJson/aboutMe.json'
import Bike from './img/bike.jpeg'

const jsonInfo= data
export default function AboutMe({classes}){
    classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
        return(

            <body>
                <div className={classes.aboutMe}>
                    <div className={classes.drawerHeader}/>
                    <ImgMediaCard title={jsonInfo[0].title} text={jsonInfo[0].text} imageName={Bike} description={jsonInfo[0].title}/>
                    <ImgMediaCardLink title={jsonInfo[12].title} text={jsonInfo[12].text} imageName={jsonInfo[12].imageLink} link={jsonInfo[12].link} secondText={jsonInfo[12].secondText} description={jsonInfo[12].title}/>
                    <ImgMediaCardLink title={jsonInfo[3].title} text={jsonInfo[3].text} imageName={jsonInfo[3].imageLink} link={jsonInfo[3].link} secondText={jsonInfo[3].secondText} description={jsonInfo[3].title}/>
                    <ImgMediaCardLink title={jsonInfo[19].title} text={jsonInfo[19].text} imageName={jsonInfo[19].imageLink} link={jsonInfo[19].link} secondText={jsonInfo[19].secondText} description={jsonInfo[19].title}/>
                    <ImgMediaCardLink title={jsonInfo[1].title} text={jsonInfo[1].text} imageName={jsonInfo[1].imageLink} link={jsonInfo[1].link} secondText={jsonInfo[1].secondText} description={jsonInfo[1].title}/>
                    <ImgMediaCardLink title={jsonInfo[2].title} text={jsonInfo[2].text} imageName={jsonInfo[2].imageLink} link={jsonInfo[2].link} secondText={jsonInfo[2].secondText} description={jsonInfo[2].title}/>

                </div>
            </body>

        )
    
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    aboutMe:{
        display: 'flex',
        alignContent:'center',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        marginBottom: '3%',
        [theme.breakpoints.down("1300")]: {
          marginTop: '20%',
          marginBottom: '10%',
        },
    }
    }));
