import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Link, useHistory } from "react-router-dom";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Button, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ProjectGallery from './ProjectGallery';
import Abilities from './Abilities';
import { margin } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  root:{
    color: '#fafafa',
    backgroundColor: '#171E28', 
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '50%',
    [theme.breakpoints.down("1300")]:{
      maxWidth: 345,
    }
  },
  button:{
    backgroundColor: '#2e343d',
    marginBottom: '1.5%',

  },
  link:{
    color: '#fafafa',
    textDecoration:'none'

  }
  }));

export default function ImgMediaCard(props) {
  const classes = useStyles();
  return (
    <div style={{paddingTop: '2.5%'}}>
    <Card className={classes.root}>
        <CardMedia
          component="img"
          alt={props.description}
          height={window.innerWidth<1000 ? "140" : "275"}
          title="Contemplative Reptile"
          image={props.imageName}
        />
        <CardContent style={{marginBottom: '1.5%'}}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="#fafafa" component="p">
            {props.text}
          </Typography>
        </CardContent>
    </Card>
    </div>
  );
}

export  function ImgMediaCardLink(props) {
  const classes = useStyles();
  const history = useHistory();
  function handleOnClick(){
    if (props.link=== '/portfolio'){
      history.push('/portfolio')
    }
    else if (props.link=== '/portfolio'){
      history.push('/abilities')
    }
    else if (props.link=== 'twitter'){
    window.open('https://www.twitter.com/JuanTacho12')
  }
    else if (props.link==='git'){
      window.open('https://github.com/JuanDiaz12000')

    }
    else if (props.link==='LinkedIn'){
      window.open('https://www.linkedin.com/in/jedn/')
    }
    else if (props.link==='mexicovid19'){
      window.open('https://mexicovid19.app/')
    }
    else if (props.link ==='Expansion'){      
      window.open('https://expansionurbanamty.mx/')
    }
    else if (props.link === 'Sentiment'){
      window.open('https://drive.google.com/file/d/13ieoFwTkUj8H71xhJiRdG3eplq66d2ca/view?usp=sharing')
    }
}


  return (
    <div style={{paddingTop: '2.5%'}}>
    <Card className={classes.root}>
        <CardMedia
          component="img"
          alt={props.description}
          height={window.innerWidth<1000 ? "140" : "275"}
          image={props.imageName}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="#fafafa" component="p">
          {props.text}
          </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" className={classes.button} onClick={handleOnClick}>
          <p className={classes.link} >{props.secondText}</p>
        </Button>
      </CardActions>
    </Card>
    </div>
  );
}