import {React, useEffect} from "react";
import { makeStyles} from '@material-ui/core/styles';
import ImgMediaCard from "./ImgMediaCard";
import { ImgMediaCardLink } from "./ImgMediaCard";
import data from './dataJson/aboutMe.json'
import Transportec from './img/transportec.png';
import Fetec from './img/fetec.png';
import IoT from './img/iot.png';
import Komorebi from './img/komorebi.png'
import Expansion from './img/expansion.png'
import Multi from './img/multi.png'
import CETAC from './img/cetac.png'
import { Link, useHistory } from "react-router-dom";
import ScienceQuest from './img/ScienceQuest.png';
import Tweets from './img/ds.png';
const jsonInfo= data

export default function ProjectGallery({classes}){
    classes = useStyles();
    console.log(jsonInfo)
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
        return () => {
          unlisten();
        }
      }, []);
        return(
        <body>
            <div className={classes.aboutMe}>
            <ImgMediaCard title={jsonInfo[12].title} text={jsonInfo[12].text} imageName={jsonInfo[12].imageLink} description={jsonInfo[12].title}/>
            <ImgMediaCardLink title={jsonInfo[26].title} text={jsonInfo[26].text} imageName={jsonInfo[26].imageLink} description={jsonInfo[26].title} link={jsonInfo[26].link} secondText={jsonInfo[26].secondText}/> 
            <ImgMediaCard title={jsonInfo[25].title} text={jsonInfo[25].text} imageName={jsonInfo[25].imageLink} description={jsonInfo[25].title}/> 
            <ImgMediaCardLink title={jsonInfo[22].title} text={jsonInfo[22].text} imageName={Expansion} link={jsonInfo[22].link} secondText={jsonInfo[22].secondText} description={jsonInfo[22].title}/>
            <ImgMediaCardLink title={jsonInfo[13].title} text={jsonInfo[13].text} imageName={jsonInfo[13].imageLink} link={jsonInfo[13].link} secondText={jsonInfo[13].secondText} description={jsonInfo[13].title}/>                   
            <ImgMediaCard title={jsonInfo[21].title} text={jsonInfo[21].text} imageName={Komorebi} description={jsonInfo[21].title}/>            
            <ImgMediaCard title={jsonInfo[23].title} text={jsonInfo[23].text} imageName={Multi} description={jsonInfo[23].title}/> 
            <ImgMediaCard title={jsonInfo[24].title} text={jsonInfo[24].text} imageName={CETAC} description={jsonInfo[24].title}/> 
            <ImgMediaCard title={jsonInfo[18].title} text={jsonInfo[18].text} imageName={ScienceQuest} description={jsonInfo[18].title}/>           
            <ImgMediaCard title={jsonInfo[14].title} text={jsonInfo[14].text} imageName={Fetec} description={jsonInfo[14].title}/>
            <ImgMediaCard title={jsonInfo[15].title} text={jsonInfo[15].text} imageName={Transportec} description={jsonInfo[15].title}/>
            <ImgMediaCard title={jsonInfo[16].title} text={jsonInfo[16].text} imageName={Tweets} description={jsonInfo[16].title}/>            
            <ImgMediaCard title={jsonInfo[17].title} text={jsonInfo[17].text} imageName={IoT} description={jsonInfo[17].title}/>
            <ImgMediaCardLink title={jsonInfo[19].title} text={jsonInfo[19].text} imageName={jsonInfo[19].imageLink} link={jsonInfo[19].link} secondText={jsonInfo[19].secondText} description={jsonInfo[19].title}/>
            <ImgMediaCardLink title={jsonInfo[1].title} text={jsonInfo[1].text} imageName={jsonInfo[1].imageLink} link={jsonInfo[1].link} secondText={jsonInfo[1].secondText} description={jsonInfo[1].title}/>
            <ImgMediaCardLink title={jsonInfo[2].title} text={jsonInfo[2].text} imageName={jsonInfo[2].imageLink} link={jsonInfo[2].link} secondText={jsonInfo[2].secondText} description={jsonInfo[2].title}/>
            </div>
        </body>
        )
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    aboutMe:{
        display: 'flex',
        alignContent:'center',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        marginBottom: '3%',
        [theme.breakpoints.down("1300")]: {
          marginTop: '20%',
          marginBottom: '10%',
        },
    }
    }));
