import React from 'react';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import NoteIcon from '@material-ui/icons/Note';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
/*const useStyles = makeStyles((theme) => ({

  }));*/
  const drawerWidth = 240;
  export default function NavBar({classes}) {
    classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };
    return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap>
          Juan Diaz
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
             <ChevronLeftIcon style={{fill: "#fafafa"}}/>
          </IconButton>
        </div>
        <Divider />
        <Divider />
        <List>
            <ListItem>
              <ListItemIcon><FaceIcon style={{fill: "#fafafa"}}/></ListItemIcon>
              <Link className={classes.link} to='/'>About Me</Link>
            </ListItem>
            <ListItem>
              <ListItemIcon><NoteIcon style={{fill: "#fafafa"}}/></ListItemIcon>
              <Link className={classes.link} to='/portfolio'>Project Portfolio</Link>
            </ListItem>
            <ListItem>
              <ListItemIcon><EmojiObjectsIcon style={{fill: "#fafafa"}}/></ListItemIcon>
              <Link className={classes.link} to='/abilities'>Abilities</Link>
            </ListItem>
        </List>
      </Drawer>
      </div>
          )
      
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        
      },
      link:{
        color: '#fafafa',
        textDecoration:'none'
    
      },
      appBar: {

        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor:'#171E28'
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        color: '#fafafa'
      },
      drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#171E28',
        color: '#fafafa',
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      title: {
        flexGrow: 1,
      },
    toolbar:{
        display: 'flex',
        backgroundColor:'#171E28',
        borderRadius: '25px',
        width: '90%',
        marginLeft: 'auto',
        marginRight:'auto',
    },
    list: {
        width: 250,
        color: '#fafafa',
      },
      fullList: {
        width: 'auto',
        color: '#fafafa'
      },
}));
