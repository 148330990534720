import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import NavBar from './NavBar';
import AboutMe from './AboutMe';
import Abilities from './Abilities';
import ProjectGallery from './ProjectGallery';
export default function App({classes}) {
  classes = useStyles();
  return (
    <Router>
      <NavBar/>
        <Switch>
          <Route path="/abilities">
            <Abilities/>
          </Route>
          <Route path="/portfolio">
            <ProjectGallery/>
          </Route>
          <Route path="/">
            <AboutMe/>
          </Route>
        </Switch>
    </Router>
  );
};

const useStyles = makeStyles((theme) => ({
}));